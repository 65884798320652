<style lang="scss" scoped>
.drawing-board {
    background: #f0f3fa;
    border-radius: 10px;
    box-sizing: border-box;
    padding-top: 24px;
    overflow: hidden;

    h3 {
        height: 48px;
        text-align: center;
        color: #242323;
        line-height: 48px;

    }

    .drawing-opration {
        box-sizing: border-box;
        padding: 12px 46px 32px;
        @include flexBox(space-between);

        button {
            width: 30px;
            height: 58px;
            border: none;
            background: transparent;
            outline: none;
            flex-direction: column;
            cursor: pointer;
            @include flexBox(center);

            .iconfont {
                font-size: 30px;
                color: #8e8e8e;
            }

            span {
                color: #242323;
                margin-top: 6px;
            }
        }
    }

    .canvas-box {
        width: 600px;
        height: 600px;
        position: relative;

        input {
            position: absolute;
            background: transparent;
        }
    }
}
</style>

<template>
    <section class="drawing-board" :style="{ width: `${c_w}px`, height: `${c_h}px` }">
        <h3 class="bold">白板</h3>
        <div class="canvas-box" id="canvasBox" :style="{ width: `${c_w}px`, height: `${c_h - 174}px` }" @touchmove.prevent>
            <canvas id="canvas" :width="c_w" :height="c_h - 174" @mousedown="canvasDown($event)"
                @mouseup="canvasUp($event)" @mousemove="canvasMove($event)" @touchstart="canvasDown($event)"
                @touchend="canvasUp($event)" @touchmove="canvasMove($event)">
            </canvas>
        </div>
        <div class="drawing-opration">
            <button @click="checkPrev">
                <i class="iconfont">&#xe647;</i>
                <span>撤销</span>
            </button>
            <button @click="checkClear">
                <i class="iconfont">&#xe648;</i>
                <span>清除</span>
            </button>
            <button @click="checkWrite">
                <i class="iconfont">&#xe61e;</i>
                <span>画笔</span>
            </button>
            <button @click="checkInput">
                <i class="iconfont">&#xe64a;</i>
                <span>打字</span>
            </button>
            <!-- <button @click="checkEraser">橡皮擦</button> -->
        </div>
    </section>
</template>

<script>
import _ from "lodash"; // JavaScript 实用工具库
export default {
    props: {
        c_w: {
            type: Number,
            value: 370,
        },
        c_h: {
            type: Number,
            value: 500,
        }
    },
    data() {
        return {
            canvas: null,//canvas标签id
            context: null, // canvas context
            canvasMoveUse: false, // 是否允许执行move时候绘制线条
            preDrawAry: [], //存储当前表面状态数组-上一步
            middleAry: [], //中间数组
            lineWidth: 2, // 线条宽度
            lineColor: 'black', // 线条颜色
            isWrite: true,
            pointX: 0,
            pointY: 0,
            textvalue: '',
        }
    },

    watch: {},

    created() { },

    mounted() {
        this.createCanvas();
    },
    methods: {
        /** 初始化画布 */
        createCanvas() {
            this.canvas = document.getElementById("canvas");
            this.context = this.canvas.getContext("2d");
            this.context.fillStyle = "transparent";
            //动态获取canvas画布的宽、高
            this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
        },
        beginPath(e) {
            if (e.target !== this.canvas) {
                this.context.beginPath()
            }
        },
        canvasDown(e) {
            // 让move方法可用
            this.canvasMoveUse = true;
            // client是基于整个页面的坐标
            // offset是cavas距离顶部以及左边的距离
            const canvasX = e.pageX - e.target.parentNode.offsetLeft;
            const canvasY = e.pageY - e.target.parentNode.offsetTop;
            if (this.isWrite) {
                // 设置canvas的配置
                this.context.lineWidth = this.lineWidth;
                this.context.lineCap = 'round';
                this.context.strokeStyle = this.lineColor;
                this.context.beginPath(); //清除子路径
                // 移动的起点
                this.context.moveTo(canvasX, canvasY-25);
            } else {
                this.pointX = canvasX;
                this.pointY = canvasY;
            }
            //当前绘图表面状态
            const preData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
            //当前绘图表面进栈
            // 按下相当于新的操作的开始，所以把当前记录数据放到prev中
            this.preDrawAry.push(preData);
        },
        //滑动的时候
        canvasMove(e) {
            if (this.canvasMoveUse) {
                // 只有允许移动时调用
                const t = e.target;
                let canvasX;
                let canvasY;
                // 由于手机端和pc端获取页面坐标方式不同，所以需要做出判断  changedTouches手机才有这个事件
                if (e.changedTouches&&e.changedTouches.length) {
                    canvasX = e.changedTouches[0].pageX - t.parentNode.offsetLeft;
                    canvasY = e.changedTouches[0].pageY - t.parentNode.offsetTop;
                } else {
                    canvasX = e.pageX - t.parentNode.offsetLeft;
                    canvasY = e.pageY - t.parentNode.offsetTop;
                }
                if (this.isWrite) {
                    // 连接到移动的位置并上色
                    this.context.lineTo(canvasX, canvasY-26);
                    this.context.stroke();
                } else {
                    this.context.beginPath(); //清除子路径
                    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    if (!this.$isEmpty(this.preDrawAry)) {
                        this.preDrawAry.map(item => {
                            this.context.putImageData(item, 0, 0)
                        })
                    }
                    this.context.strokeStyle = this.lineColor;
                    this.context.strokeRect(this.pointX, this.pointY, (e.offsetX - this.pointX), (e.offsetY - this.pointY));
                    this.context.closePath();
                }
            }
        },
        //松开手指
        createIpt(e) {
            let offsetX = e.offsetX;
            let offsetY = e.offsetY;
            if (!this.isWrite) {
                if (offsetX - this.pointX < 10) {
                    offsetX = 100;
                }
                if (offsetY - this.pointY < 10) {
                    offsetY = 50;
                    this.pointY = this.pointY - 30;
                }
                let input = document.createElement('input');
                let canvasArea = document.getElementById("canvasBox");
                canvasArea.appendChild(input);
                this.textvalue="";
                input.style.position = "absolute";
                input.style.background = "transparent";
                input.style.left = `${this.pointX}px`;
                input.style.top = `${this.pointY}px`;
                input.style.width = `${offsetX - this.pointX}px`;
                input.style.height = `${offsetY - this.pointY}px`;
                input.style.fontSize = `${parseInt(input.style.height) / 2}px`;
                input.style.border = "2px dashed #ccc";
                input.style.outline = 'none'
                input.focus();
                input.addEventListener('input', (e) => {
                    this.textvalue = e.target.value
                })
                this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                if (!this.$isEmpty(this.preDrawAry)) {
                    this.preDrawAry.map(item => {
                        this.context.putImageData(item, 0, 0)
                    })
                }
                input.addEventListener('blur', () => {
                    this.context.beginPath(); //清除子路径
                    this.context.fillStyle = this.lineColor;// 设置填充画笔颜色
                    this.context.font = `${parseInt(input.style.height) / 2}px sans-serif`
                    this.context.fillText(this.textvalue, parseInt(input.style.left), parseInt(input.style.top) + 0.6 * parseInt(input.style.height || 0));
                    this.context.closePath();
                    const cleardom = document.querySelectorAll('.canvas-box input')
                    Array.from(cleardom).forEach((item) => {
                        item.remove()
                    })
                })
            }
        },
        //松开手指
        async canvasUp(e) {
            await this.createIpt(e);
            this.isWrite = true;
            const preData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
            if (this.preDrawAry.length < this.middleAry.length) {
                // 在没有撤销过的情况下，将当前数据放入prev
                // 当前绘图表面进栈
                this.middleAry.push(preData)
            } else {
                // 在撤销的情况下，将在后面步骤的数据情况记录
                this.middleAry = [...this.preDrawAry]
            }
            // 设置move时不可绘制
            this.canvasMoveUse = false
        },
        checkPrev() {
            if (!this.$isEmpty(this.preDrawAry)) {
                const popData = this.preDrawAry.pop();
                const cleardom = document.querySelectorAll('.canvas-box input')
                Array.from(cleardom).forEach((item) => {
                    item.remove()
                })
                this.context.putImageData(popData, 0, 0);
            }
        },
        checkEraser() {
            this.lineColor = 'white';
            this.lineWidth = 30
        },
        checkWrite() {
            this.isWrite = true;
            this.lineColor = 'black';
            this.lineWidth = 1
        },
        checkClear() {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            const cleardom = document.querySelectorAll('.canvas-box input')
            Array.from(cleardom).forEach((item) => {
                item.remove()
            })
            // 清空数据
            this.preDrawAry = [];
            // middleAry恢复到默认数据
            this.middleAry = [this.middleAry[0]];
        },
        checkInput() {
            this.isWrite = false;
        },
        isPc() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return !flag;
        }
    }
};
</script>
