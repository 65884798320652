<!--
学生翻拍效果
-->

<style lang="scss" scoped>
.boxpage{
    min-height: 600px;
    max-height: 800px;
    overflow: auto;
    position: relative;
    width: 100%;
    .setnumber{
        height: 140px;
        text-align: center;
        .txt{
            font-size: 18px;
            color: #666666;
            line-height: 60px;
        }
    }
    .userlist{
        padding: 30px 0 30px 30px;
        display: inline-block;
        min-height: 200px;
        .item{
            height: 150px;
            width: 0;
            -webkit-animation:setwidth linear 0.5s forwards; 
            animation:setwidth linear 0.5s forwards; 
            margin: 0 30px 30px 0;
            float: left;
            z-index: 0;
            position: relative;
            overflow: hidden;
            .block{
                width: 120px;
                height: 150px;
                text-align: center;
                border-radius: 5px;
                background-color: #f1f1f1;
                box-shadow: 0 0 1px #7539ff;
                overflow: hidden;
                border-radius: 10px;
                box-sizing: border-box;
                position: relative;
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 180deg);
                transform: perspective(400px) rotate3d(0, 1, 0, 180deg);
                
                -webkit-animation:flipInY 1s linear 0.8s forwards; 
                animation:flipInY 1s linear 0.8s forwards; 
                .name{
                    margin-top: 12px;
                    font-size: 18px;
                }
                .values{
                    padding: 20px 0;
                    position: relative;
                    z-index: 5;
                    opacity: 0;
                    -webkit-animation:setopacity 0s linear 1.3s forwards; 
                    animation:setopacity 0s linear 1.3s forwards; 
                }
                .zd{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #f1f1f1;
                    z-index: 1;
                }

            }
        }
        .boxes{
            width: 120px;
            height: 150px;
            writing-mode: vertical-lr;
            text-orientation: upright;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            font-size: 30px;
            font-weight: bold;
            background-color: #f3f3f3;
            border-radius: 5px;
            box-shadow: 0 0 1px #f3f3f3;
            color: #595959;
            position: relative;
            z-index: 5;
        }
    }
    @keyframes flipInY {
        0% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 180deg);
                    transform: perspective(400px) rotate3d(0, 1, 0, 180deg);
            -webkit-transition-timing-function: ease-in;
                    transition-timing-function: ease-in;
        }

        50% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            -webkit-transition-timing-function: ease-in;
                    transition-timing-function: ease-in;
        }

        70% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
                    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            -webkit-transition-timing-function: ease-in;
                    transition-timing-function: ease-in;
        }

        80% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
                    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            opacity: 1;
        }

        90% {
            -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
                    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
        }

        100% {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }
    @keyframes setwidth {
        0%{
            width: 0;
        }
        99%{
            width: 117px;
            overflow: hidden;
        }
        100%{
            width: 120px;
            overflow: initial;
        }

    }
    @keyframes setopacity {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }

    }

}
::v-deep{
    .el-input-number__decrease{
        border-radius:10px 0 0 10px;
        height: 44px;
        line-height: 44px;
    }
    .el-input-number__increase{
        border-radius:0 10px 10px 0;
        height: 44px;
        line-height: 44px;
    }
}
</style>
<template>
    <section>
        <el-dialog
        title="随机答题"
        width="860px"
        :visible.sync="show" @close="colseStudentFn">
        <div class="boxpage">
            <div class="userlist">
                <div class="item" :class="item.showitem?'showitem':''" v-for="(item,index) in studentInfoList" :key="index">
                    <div class="block">
                        <div class="zd">
                            <img class="login-bg" src="@assets/images/cardBack.jpg" alt />
                        </div>
                        <div class="values">
                            <el-avatar :size="62" :src="item.stuser_image" />
                            <p class="name">{{ item.stuser_name }}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="boxes">名 册</div> -->
            </div>
            <div class="setnumber" v-loading="showsetnumber">
                <div class="txt">
                    请输入抽取数量
                </div>
                <div class="setnumber">
                    <el-input-number v-model="num" :min="1" :max="list.length" :precision="0" label="请输入数量"></el-input-number>
                    <el-button style="margin-left: 10px;" type="primary" size="small" @click="getuserinfo">确定</el-button>
                </div>
            </div>
        </div>
        </el-dialog>
    </section>
</template>

<script>
export default {
    name: 'drawStudent',
    props: {
        //展开上传
        value: {
            type: Boolean,
            default: false,
        },
        userList:{
            type:Array,
            default:[]
        }
    },
    data () {
        return {
            // 是否显示
            show:this.value,
            // 原始数组
            list:this.studentList,
            // 新的学生数组
            newlist:[],
            // 抽取出来的学生数组
            studentInfoList:[],
            num:1,
            showsetnumber:false
        }
    },
    watch: {
        value(n, o) {
            this.show = n;
            this.newlist=[]
            this.studentInfoList=[]
            this.num=1
        },
        userList(n, o) {
            this.list = n;
        },
    },

    created () {
     },

    mounted () {
        
    },
    methods: {
        //关闭选择学生
        colseStudentFn(){
            this.$emit("colseStudentFn");
        },
        getuserinfo(){
            this.newlist= JSON.parse(JSON.stringify(this.list));
            this.studentInfoList=[];
            this.setitem(1)
            this.showsetnumber=true
            
        },
        // 设置数据
        setitem(number){
            setTimeout(() => {
                // 生成一个随机索引
                const randomIndex = Math.floor(Math.random() * this.newlist.length);
                // 根据随机索引选择对象
                this.studentInfoList.push(this.newlist[randomIndex]);
                // 从数组中删除选中的对象
                this.newlist.splice(randomIndex, 1);
                if(number<this.num){
                    this.setitem(number+1)
                }else{
                    this.showsetnumber=false
                }  
            }, 600);
            
        }
    }   
}
</script>